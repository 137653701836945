<template>
    <div class="start_active">
        <img class="title" src="@/assets/account/title-min.png" alt="">
        <div class="prize">
            <img v-if="price.pic_link" :src="price.pic_link.url" alt="">
            <div> 
                {{price.goods_name}}
            </div>
        </div>
        <div class="code">
            <img src="@/assets/account/code-min.png" alt="">
        </div>
        <img class="row" src="@/assets/account/Group952.png" alt="">
        <img class="bottom" src="@/assets/account/login-bottom-min.png" alt="">
        <img class="bottom1" src="@/assets/account/Group946.png" alt="">
    </div>
</template>

<style scoped lang="less">
.start_active{width:100%; height: 100%;  background-color: rgb(3, 10, 65); background-image: url('../../../assets/account/loginbg-min.png'); background-size: 100%; padding: 37px 0 70px; background-repeat: no-repeat;overflow-y: scroll;
    .title { width: 94%;height: auto; margin:auto; display: block;}
    .bottom { width: 100%;height: auto;position: fixed;bottom: 0; display: block; }
    .row { width: 100%; position: absolute;height: auto;top: 103vw; }
    .prize { width: 238px;height: 203px; margin: 10px auto; background-image: url('../../../assets/account/Group951-min.png'); background-size: 100% 100%; position: relative;
        div { 
            position: absolute;bottom: 6px; text-align: center; background-image: url('../../../assets/account//Rectangle163.png'); background-size: 100% 100%; width: 100%;height: 50px; line-height: 52px; color: #030A41; font-weight: bold; letter-spacing: 1px;
        }
        img {
            display: block;
            margin: auto;
            width: 65%;
            border-radius: 50%;
        }
    }
    .code {
         img { display: block; width: 257px;margin: auto;}
    }
    .bottom1 { width: 70vw; height: auto; position: absolute;left: 15vw; display: block; bottom: 2vw; }
}
</style>

<script>
import {  mooby_luck_goods } from '@/api/zh/mine.js'
export default {
    data() {
        return {
            price: {}
        }
    },
    methods: {
        getLuckGoods() {
            mooby_luck_goods().then(res => { 
                if (res) {
                    this.price = res.data;
                }else{
                    this.$notify({ type: 'warning', duration: 2000, message: '暂无奖品！' })
                    this.$router.push('/zh/account')
                }
            })
        }
    },
    created() {
        this.getLuckGoods();
    }
}
</script>