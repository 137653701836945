import { render, staticRenderFns } from "./boon.vue?vue&type=template&id=37238173&scoped=true&"
import script from "./boon.vue?vue&type=script&lang=js&"
export * from "./boon.vue?vue&type=script&lang=js&"
import style0 from "./boon.vue?vue&type=style&index=0&id=37238173&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37238173",
  null
  
)

export default component.exports